import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ReferralCard from '../card/ReferralCard';
import ButtonCtaReferral from '../button/ButtonCtaReferral';
import { CUSTOM_EVENT, pushEvent } from '../../configs/clevertap';
import GTM_TRACKERS from '../../../constants/gtm-trackers';

export default function About() {
  return (
    <ReferralCard title="Yuk Kenalan dengan Programnya">
      <div className="flex flex-col gap-y-4 my-4 px-4">
        <div className="flex gap-x-4 justify-center">
          <div className="w-[100px] md:w-[200px]">
            <StaticImage
              src="../../images/referral/article-2/friend-1.webp"
              alt="friend-1"
              objectFit="contain"
            />
          </div>
          <div className="w-[100px] md:w-[200px]">
            <StaticImage
              src="../../images/referral/article-2/friend-2.webp"
              alt="friend-2"
              objectFit="contain"
            />
          </div>
          <div className="w-[100px] md:w-[200px]">
            <StaticImage
              src="../../images/referral/article-2/friend-3.webp"
              alt="intro-3"
              objectFit="contain"
            />
          </div>
        </div>
        <p className="text-sm font-normal text-center">
          Program referral dari DepositoBPR by Komunal dengan keuntungan mulai
          dari 100rb per teman yang berhasil kamu ajak deposito. Makin banyak
          teman yang deposito, makin berlipat ganda bonus yang kamu dapat. Yuk
          gabung sekarang!
        </p>
        <div className={`md:flex justify-center ${GTM_TRACKERS.REFERRAL.PELAJARI_LEBIH_LANJUT}`}>
          <ButtonCtaReferral
            isInternal
            onClick={() => pushEvent(CUSTOM_EVENT.PELAJARI_LEBIH_LANJUT)}
            to="/referral/learn-more"
            text="Pelajari Lebih Lanjut"
            className="md:w-80"
          />
        </div>
        <iframe
          className="aspect-video"
          src="https://www.youtube.com/embed/hRMyrYEWvGg?si=XZdYfiwXH10yXxzq"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </ReferralCard>
  );
}
