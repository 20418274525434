import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';
import ReferralCard from '../card/ReferralCard';
import ButtonCtaReferral from '../button/ButtonCtaReferral';
import { CUSTOM_EVENT, pushEvent } from '../../configs/clevertap';
import GTM_TRACKERS from '../../../constants/gtm-trackers';

export default function HowTo() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleToggleAccordion = (index) => {
    if (index === activeAccordion) {
      setActiveAccordion(null);
      return;
    }

    setActiveAccordion(index);
  };

  return (
    <ReferralCard title="Cara Gabung Program Ini">
      <div className="flex flex-col gap-y-4 my-4 px-4 md:hidden">
        <div className="flex flex-col justify-between gap-y-4 border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between items-center"
            onClick={() => handleToggleAccordion(1)}
            role="presentation"
          >
            <div className="flex items-center gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-6 h-6 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                1
              </span>
              <h5 className="text-sm font-bold px-2 w-60">
                Download Aplikasi DepositoBPR by Komunal
              </h5>
            </div>
            <FontAwesomeIcon
              icon={activeAccordion === 1 ? faAngleDown : faAngleRight}
              className="cursor-pointer"
              onClick={() => handleToggleAccordion(1)}
            />
          </div>
          <div className={`${activeAccordion === 1 ? 'flex flex-col gap-y-2' : 'hidden'}`}>
            <p className="text-xs font-normal">
              Download aplikasi di Google Playstore atau Appstore.
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-1.webp"
              alt="Download Aplikasi DepositoBPR by Komunal"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-y-4 border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between items-center"
            onClick={() => handleToggleAccordion(2)}
            role="presentation"
          >
            <div className="flex items-center gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-6 h-6 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                2
              </span>
              <h5 className="text-sm font-bold px-2 w-60">
                Daftar dan buat akun DepositoBPR by Komunal
              </h5>
            </div>
            <FontAwesomeIcon
              icon={activeAccordion === 2 ? faAngleDown : faAngleRight}
              className="cursor-pointer"
              onClick={() => handleToggleAccordion(2)}
            />
          </div>
          <div className={`${activeAccordion === 2 ? 'flex flex-col gap-y-2' : 'hidden'}`}>
            <p className="text-xs font-normal">
              Selesaikan proses registrasi akun kamu, supaya bisa dapat kode referral kamu.
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-2.webp"
              alt="Daftar dan buat akun DepositoBPR by Komunal"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-y-4 border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between items-center"
            onClick={() => handleToggleAccordion(3)}
            role="presentation"
          >
            <div className="flex items-center gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-6 h-6 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                3
              </span>
              <h5 className="text-sm font-bold px-2 w-60">
                Bagikan Kode Referralmu
              </h5>
            </div>
            <FontAwesomeIcon
              icon={activeAccordion === 3 ? faAngleDown : faAngleRight}
              className="cursor-pointer"
              onClick={() => handleToggleAccordion(3)}
            />
          </div>
          <div className={`${activeAccordion === 3 ? 'flex flex-col gap-y-2' : 'hidden'}`}>
            <p className="text-xs font-normal">
              Ajak Teman dan Keluarga berdeposito sebanyak-banyaknya dan dapatkan Bonus hingga Jutaan Rupiah!
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-3.webp"
              alt="Bagikan Kode Referralmu"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
        <div className={GTM_TRACKERS.REFERRAL.DAFTAR_SEKARANG}>
          <ButtonCtaReferral
            onClick={() => pushEvent(CUSTOM_EVENT.DAFTAR_SEKARANG)}
            isInternal
            to="/referral/register"
            text="Daftar Sekarang"
          />
        </div>
      </div>
      <div className="hidden md:flex md:flex-row md:gap-x-4 md:my-4 md:px-4">
        <div className="w-1/3 flex flex-col justify-between border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between"
            role="presentation"
          >
            <div className="flex gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-5 h-5 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                1
              </span>
              <h5 className="text-sm font-bold px-2 w-24 lg:w-40">
                Download Aplikasi DepositoBPR by Komunal
              </h5>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-sm font-normal">
              Download aplikasi di Google Playstore atau Appstore.
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-1.webp"
              alt="Download Aplikasi DepositoBPR by Komunal"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
        <div className="w-1/3 flex flex-col justify-between border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between"
            role="presentation"
          >
            <div className="flex gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-5 h-5 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                2
              </span>
              <h5 className="text-sm font-bold px-2 w-24 lg:w-40">
                Daftar dan buat akun DepositoBPR by Komunal
              </h5>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-sm font-normal">
              Selesaikan proses registrasi akun kamu, supaya bisa dapat kode referral kamu.
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-2.webp"
              alt="Daftar dan buat akun DepositoBPR by Komunal"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
        <div className="w-1/3 flex flex-col justify-between border-[1px] border-green-light40 bg-green-light10 rounded-xl p-4">
          <div
            className="flex justify-between"
            role="presentation"
          >
            <div className="flex gap-x-2">
              {/* eslint-disable-next-line max-len */}
              <span className="flex justify-center items-center w-5 h-5 bg-green-light40 p-1 text-xs text-blue-light40 font-bold rounded-full">
                3
              </span>
              <h5 className="text-sm font-bold px-2 w-24 lg:w-40">
                Bagikan Kode Referralmu
              </h5>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-sm font-normal">
              Ajak Teman dan Keluarga berdeposito sebanyak-banyaknya dan dapatkan Bonus hingga Jutaan Rupiah!
            </p>
            <StaticImage
              src="../../images/referral/article-4/step-3.webp"
              alt="Bagikan Kode Referralmu"
              objectFit="contain"
              className="items-center"
            />
          </div>
        </div>
      </div>
      <div className={`hidden md:flex md:justify-center md:pb-4 md:px-8 ${GTM_TRACKERS.REFERRAL.DAFTAR_SEKARANG}`}>
        <ButtonCtaReferral
          onClick={() => pushEvent(CUSTOM_EVENT.DAFTAR_SEKARANG)}
          isInternal
          to="/referral/register"
          text="Daftar Sekarang"
          className="md:w-80"
        />
      </div>
    </ReferralCard>
  );
}
