import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ReferralCard from '../card/ReferralCard';

export default function Persuation() {
  return (
    <ReferralCard className="z-20" title="Keuntungan Mengikuti Program Ini">
      <div className="flex flex-col md:flex-row md:justify-between gap-y-6 py-6 px-11">
        <div className="md:w-60 flex flex-col items-center gap-y-2">
          <div className="relative">
            <span
              // eslint-disable-next-line max-len
              className="text-xs text-white font-bold bg-blue-light40 rounded-full border-[0.8px] p-2 w-7 h-7 flex justify-center items-center border-green-light40 absolute left-0 top-0 z-20"
            >
              01.
            </span>
            <StaticImage
              src="../../images/referral/article-1/step-1.webp"
              alt="step-1"
              objectFit="contain"
              className="w-[100px]"
            />
          </div>
          <p className="text-sm font-normal text-center">
            <span className="font-bold">Untung lebih tinggi</span> dibanding program lain yang sejenis.
          </p>
        </div>
        <div className="md:w-60 flex flex-col items-center gap-y-2">
          <div className="relative">
            <span
              // eslint-disable-next-line max-len
              className="text-xs text-white font-bold bg-blue-light40 rounded-full border-[0.8px] p-2 w-7 h-7 flex justify-center items-center border-green-light40 absolute left-0 top-0 z-20"
            >
              02.
            </span>
            <StaticImage
              src="../../images/referral/article-1/step-2.webp"
              alt="step-2"
              objectFit="contain"
              className="w-[100px]"
            />
          </div>
          <p className="text-sm font-normal text-center">
            <span className="font-bold">Penghasilan sampingan</span> dengan waktu kerja fleksibel.
          </p>
        </div>
        <div className="md:w-60 flex flex-col items-center gap-y-2">
          <div className="relative">
            <span
              // eslint-disable-next-line max-len
              className="text-xs text-white font-bold bg-blue-light40 rounded-full border-[0.8px] p-2 w-7 h-7 flex justify-center items-center border-green-light40 absolute left-0 top-0 z-20"
            >
              03.
            </span>
            <StaticImage
              src="../../images/referral/article-1/step-3.webp"
              alt="step-3"
              objectFit="contain"
              className="w-[100px]"
            />
          </div>
          <p className="text-sm font-normal text-center md:w-40">
            <span className="font-bold">Bisa mulai dari teman</span> atau kenalan.
          </p>
        </div>
      </div>
    </ReferralCard>
  );
}
