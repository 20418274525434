import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ReferralCard from '../card/ReferralCard';
import ButtonCtaReferral from '../button/ButtonCtaReferral';
import CONTACT_CONFIG from '../../configs/contactConfig';
import { CUSTOM_EVENT, pushEvent } from '../../configs/clevertap';

const { SOCMED } = CONTACT_CONFIG;

export default function Testimonial() {
  return (
    <ReferralCard
      title="Gabung Ajak Teman Bantu Tambah Penghasilan"
    >
      <div className="flex flex-col gap-y-4 my-4 px-4 lg:grid lg:grid-cols-3 lg:gap-x-4">
        <div className="flex flex-col gap-y-4 border-[1px] border-green-light40 rounded-xl px-8 py-4">
          <div className="flex gap-x-2">
            <StaticImage
              src="../../images/referral/article-3/testimoni-1.webp"
              alt="Elisa"
              objectFit="cover"
              className="w-12 h-12 rounded-full"
            />
            <h5 className="text-sm font-extrabold self-center">Elisa</h5>
          </div>
          <div className="flex justify-start gap-x-2">
            {[...Array(5)].map((_, index) => (
              <StaticImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src="../../images/referral/article-3/star.svg"
                alt="star"
                objectFit="cover"
              />
            ))}
          </div>
          <p className="text-xs leading-5 font-medium">
            Jujur benefit dari program referral Komunal ini menarik dan bermanfaat banget!
            Saya jadi dapat income tambahan setiap bulannya, selain dari benefit deposito itu sendiri.
            Semoga program ini bisa terus berlanjut, agar menjadi motivasi buat deposan lain untuk membuka akun DepositoBPR di Komunal.
          </p>
        </div>
        <div className="flex flex-col gap-y-4 border-[1px] border-green-light40 rounded-xl px-8 py-4">
          <div className="flex gap-x-2">
            <StaticImage
              src="../../images/referral/article-3/testimoni-2.webp"
              alt="Aida"
              objectFit="cover"
              className="w-12 h-12 rounded-full"
            />
            <h5 className="text-sm font-extrabold self-center">Aida</h5>
          </div>
          <div className="flex justify-start gap-x-2">
            {[...Array(5)].map((_, index) => (
              <StaticImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src="../../images/referral/article-3/star.svg"
                alt="star"
                objectFit="cover"
              />
            ))}
          </div>
          <p className="text-xs leading-5 font-medium">
            Keren, ini pertama kali nya aku dapet bonus referral sebanyak itu.
            Praise God 😇. Serius.. Modal promoin investasi ke org terdekat malah mereka juga tertarik.
            Seneng nya double2 banget. Soalnya aku gak mau cuan sendirian dong..
            Apalagi klo bisa buat mereka juga melek akan investasi yg mudah dan aman..
            Thankss DepositoBPR By Komunal.
          </p>
        </div>
        <div className="flex flex-col gap-y-4 border-[1px] border-green-light40 rounded-xl px-8 py-4">
          <div className="flex gap-x-2">
            <StaticImage
              src="../../images/referral/article-3/testimoni-3.webp"
              alt="Celline"
              objectFit="cover"
              className="w-12 h-12 rounded-full"
            />
            <h5 className="text-sm font-extrabold self-center">Celline</h5>
          </div>
          <div className="flex justify-start gap-x-2">
            {[...Array(5)].map((_, index) => (
              <StaticImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src="../../images/referral/article-3/star.svg"
                alt="star"
                objectFit="cover"
              />
            ))}
          </div>
          <p className="text-xs leading-5 font-medium">
            Menguntungkan sekali, teman dapat cashback,
            kita yang ngajak orang juga dapat cashback terlebih lagi kalau ajak teman maka bisa dapat vocer cashback deposito tambahan,
            akhirnya jadi double cuan.
            Gak ada ruginya referral Komunal, sudah aman, cuan pula.
          </p>
        </div>
        <div className="flex flex-col gap-y-4 border-[1px] border-green-light40 rounded-xl px-8 py-4">
          <div className="flex gap-x-2">
            <StaticImage
              src="../../images/referral/article-3/testimoni-4.webp"
              alt="Yenni Tresnawati"
              objectFit="cover"
              className="w-12 h-12 rounded-full"
            />
            <h5 className="text-sm font-extrabold self-center">Yenni Tresnawati</h5>
          </div>
          <div className="flex justify-start gap-x-2">
            {[...Array(5)].map((_, index) => (
              <StaticImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src="../../images/referral/article-3/star.svg"
                alt="star"
                objectFit="cover"
              />
            ))}
          </div>
          <p className="text-xs leading-5 font-medium">
            Saya sangat senang dengan program referral Ajak Teman Dapat Bonus!
            Dengan mengajak teman-teman saya bergabung,
            saya tidak hanya membantu mereka mendapatkan manfaat dari layanan yang sama,
            tetapi saya juga mendapatkan bonus yang sangat bermanfaat.
            Ini bukan hanya tentang mendapatkan tambahan penghasilan,
            tetapi juga tentang memperluas jaringan sosial saya dan memberikan nilai tambah kepada orang-orang di sekitar saya.
            Terima kasih Komunal🫶🏻🫰🏻✨
          </p>
        </div>
        <div className="flex flex-col gap-y-4 border-[1px] border-green-light40 rounded-xl px-8 py-4">
          <div className="flex gap-x-2">
            <StaticImage
              src="../../images/referral/article-3/testimoni-5.webp"
              alt="Syahroni"
              objectFit="cover"
              className="w-12 h-12 rounded-full"
            />
            <h5 className="text-sm font-extrabold self-center">Syahroni</h5>
          </div>
          <div className="flex justify-start gap-x-2">
            {[...Array(5)].map((_, index) => (
              <StaticImage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src="../../images/referral/article-3/star.svg"
                alt="star"
                objectFit="cover"
              />
            ))}
          </div>
          <p className="text-xs leading-5 font-medium">
            Program ajak teman DepositoBPR by Komunal sungguh luar biasa, saya semakin semangat untuk mengajak teman membuka akun dan melakukan investasi.
            Selain langsung dapat bonus program referral, keuntungan dari investasi juga sangat menjanjikan dengan bunga yang kompetitif.
          </p>
        </div>
      </div>
      <div className="flex justify-center pb-4 px-4">
        <ButtonCtaReferral
          onClick={() => {
            pushEvent(CUSTOM_EVENT.GABUNG_KOMUNITAS_REFERRAL);
            window.open(SOCMED.TELEGRAM, '_blank');
          }}
          text="Gabung Komunitas Referral"
          className="md:w-80"
        />
      </div>
    </ReferralCard>
  );
}
