import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ReferralCard from '../card/ReferralCard';
import ButtonCtaReferral from '../button/ButtonCtaReferral';

const faqs = [
  {
    id: 1,
    question: 'Apa itu program Ajak Teman Dapat Bonus?',
    // eslint-disable-next-line max-len
    answer: 'Ajak Teman Dapat Bonus adalah program referral khusus dari DepositoBPR by Komunal yang memberikan komisi Rp100,000 bagi referrer setiap berhasil mengajak 1 teman deposito pertama. Program ini juga memberikan bonus tambahan Rp250.000 setiap berhasil mengajak 5 teman, dan berlaku kelipatan. Komisi akan sah jika teman yang diajak deposito min. Rp 1 juta dengan tenor min. 3 bulan, maksimal 30 hari sejak registrasi.',
  },
  {
    id: 2,
    question: 'Apa itu aplikasi DepositoBPR by Komunal?',
    // eslint-disable-next-line max-len
    answer: 'DepositoBPR by Komunal adalah aplikasi marketplace pertama untuk produk deposito dari ratusan BPR di seluruh Indonesia. BPR adalah bank yang melaksanakan kegiatan usaha secara konvensional atau berdasarkan prinsip syariah, yang dalam kegiatannya tidak menyediakan jasa lalu lintas pembayaran. Seluruh BPR yang tersedia dalam aplikasi DepositoBPR by Komunal telah terdaftar dan diawasi oleh Otoritas Jasa keuangan (OJK).',
  },
  {
    id: 3,
    question: 'Bagaimana cara bergabung menjadi referrer Ajak Teman Dapat Bonus?',
    answer: 'Referrer perlu memiliki akun DepositoBPR by Komunal aktif agar bisa mendapatkan kode referral unik yang bisa disebarkan ke seluruh teman.',
  },
  {
    id: 4,
    question: 'Kenapa saya harus mengikuti program Ajak Teman Dapat Bonus?',
    // eslint-disable-next-line max-len
    answer: 'Melalui program Ajak Teman dapat Bonus, kamu akan mendapatkan KOMISI BERSIH setiap akhir periode, kesempatan untung hingga puluhan juta rupiah, kerja dari rumah, hingga kesempatan mengikuti training spesial.',
  },
  {
    id: 5,
    question: 'Bagaimana sistem pembayaran komisi saya setelah mengajak teman deposito?',
    answer: 'Pencairan komisi referral dilakukan maksimal H+14 hari kerja setelah periode selesai.',
  },
  {
    id: 6,
    question: 'Apa saja hal yang memungkinkan saya tidak mendapatkan komisi?',
    // eslint-disable-next-line max-len
    answer: 'Teman yang diajak tidak melakukan deposito pertamanya maksimal 30 hari sejak registrasi.Referrer dapat menggunakan berbagai platform yang dimiliki untuk mempromosikan produk DepositoBPR by Komunal. Gunakan asset marketing pada halaman ini untuk membantu kamu mempromosikan produk DepositoBPR ke teman, kerabat, keluarga, saudara, atau kumpulan.',
  },
  {
    id: 7,
    question: 'Bagaimana cara mempromosikan produk DepositoBPR?',
    // eslint-disable-next-line max-len
    answer: 'Referrer dapat menggunakan berbagai platform yang dimiliki untuk mempromosikan produk DepositoBPR by Komunal. Gunakan asset marketing pada halaman ini untuk membantu kamu mempromosikan produk DepositoBPR ke teman, kerabat, keluarga, saudara, atau kumpulan.',
  },
];

export default function Faq() {
  const [activeAccordions, setActiveAccordions] = useState([]);

  const handleToggleAccordions = (id) => {
    if (activeAccordions.includes(id)) {
      setActiveAccordions((prevState) => prevState.filter((item) => item !== id));
      return;
    }

    setActiveAccordions((prevState) => [...prevState, id]);
  };

  return (
    <ReferralCard title="FAQ">
      <div className="flex flex-col gap-y-4 my-4 px-4">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className="flex flex-col gap-y-2 p-6 border-[1px] border-gray rounded-lg"
          >
            <div
              onClick={() => handleToggleAccordions(faq.id)}
              role="presentation"
              className="flex justify-between items-center gap-x-2"
            >
              <h4 className="text-sm font-semibold">{faq.question}</h4>
              <FontAwesomeIcon
                icon={activeAccordions.includes(faq.id) ? faAngleDown : faAngleRight}
                className="cursor-pointer"
                onClick={() => handleToggleAccordions(faq.id)}
              />
            </div>
            <p className={`${activeAccordions.includes(faq.id) ? 'block' : 'hidden'} text-sm text-justify`}>{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center pb-4 px-4">
        <ButtonCtaReferral
          isInternal
          to="/promo/ajak-teman-dan-kamu-bisa-dapatkan-bonus-berlimpah"
          text="Pelajari Lebih Lanjut"
          className="md:w-80"
        />
      </div>
    </ReferralCard>
  );
}
